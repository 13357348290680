:root {
  --label-black5: #12121d0d;
}

.work {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.work .div {
  width: 1922px;
  height: 9836px;
  position: relative;
}

.work .intro {
  width: 1920px;
  height: 899px;
  position: absolute;
  top: 0;
  left: 0;
}

.work .bottomimage {
  width: 1920px;
  height: 2770px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.work .overlap {
  width: 1920px;
  height: 3159px;
  position: absolute;
  top: 899px;
  left: 0;
}

.work .the-challenge {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 1920px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.work .group {
  background-color: #1616cc;
  width: 1921.25px;
  height: 540.35px;
  margin-right: -1.25px;
  position: relative;
}

.work .group-2 {
  width: 1512px;
  height: 264px;
  position: relative;
  top: 128px;
  left: 206px;
}

.work .text-wrapper {
  color: #fff;
  letter-spacing: -.17px;
  text-align: center;
  white-space: nowrap;
  width: 206px;
  font-family: Inter, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.work .p {
  color: #fff;
  letter-spacing: -.24px;
  width: 1508px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 36px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 84px;
  left: 0;
}

.work .system {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 1920px;
  display: flex;
  position: absolute;
  top: 540px;
  left: 0;
}

.work .design-systeme-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  margin-right: -1.25px;
  display: inline-flex;
  position: relative;
}

.work .design-systeme {
  background-image: url("base.cee428fa.svg");
  background-size: 100% 100%;
  width: 1921.25px;
  height: 1080.7px;
  position: relative;
}

.work .mask-group {
  width: 1921px;
  height: 1081px;
  position: absolute;
  top: 0;
  left: 0;
}

.work .screens {
  width: 1920px;
  height: 1069px;
  position: absolute;
  top: 1589px;
  left: 0;
}

.work .group-wrapper {
  background-color: #fafafa;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10.01px;
  width: 1920px;
  height: 581px;
  padding: 64.04px 203.13px 88.06px;
  display: flex;
  position: absolute;
  top: 2578px;
  left: 0;
}

.work .group-3 {
  width: 1514.98px;
  height: 428.28px;
  margin-right: -1.25px;
  position: relative;
}

.work .text-wrapper-2 {
  color: #7d7d7d;
  letter-spacing: -.17px;
  width: 614px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.work .the-product-design {
  color: #7d7d7d;
  letter-spacing: 0;
  width: 1508px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 116px;
  left: 3px;
}

.work .homepage {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 4058px;
  left: 0;
}

.work .overlap-group-wrapper {
  width: 1924px;
  height: 1200px;
  margin-right: -4px;
  position: relative;
}

.work .overlap-group {
  background-image: url("mask-group-3.a45fd453.png");
  background-size: 100% 100%;
  width: 1920px;
  height: 1200px;
  position: relative;
}

.work .text-wrapper-3 {
  color: #1616cc;
  letter-spacing: -.14px;
  text-align: center;
  white-space: nowrap;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 64px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 406px;
  left: 960px;
}

.work .text-wrapper-4 {
  color: #7d7d7d;
  letter-spacing: 0;
  width: 756px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  position: absolute;
  top: 514px;
  left: 960px;
}

.work .product-page {
  background-color: #fafafa;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 5258px;
  left: 0;
}

.work .div-wrapper {
  width: 1924px;
  height: 718px;
  margin-right: -4px;
  position: relative;
}

.work .overlap-group-2 {
  background-image: url("mask-group-2.9474b7f2.png");
  background-size: 100% 100%;
  width: 1920px;
  height: 718px;
  position: relative;
}

.work .text-wrapper-5 {
  color: #7d7d7d;
  letter-spacing: -.14px;
  white-space: nowrap;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 64px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 170px;
  left: 144px;
}

.work .text-wrapper-6 {
  color: #7d7d7d;
  letter-spacing: 0;
  width: 526px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  position: absolute;
  top: 270px;
  left: 144px;
}

.work .checkout {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 1090px;
  display: inline-flex;
  position: absolute;
  top: 5976px;
  left: 0;
}

.work .group-4 {
  width: 1924px;
  height: 1094px;
  margin-bottom: -4px;
  margin-right: -4px;
  position: relative;
}

.work .overlap-group-3 {
  background-image: url("mask-group-1.f528fdf7.png");
  background-size: 100% 100%;
  width: 1920px;
  height: 1094px;
  position: relative;
}

.work .text-wrapper-7 {
  color: #1616cc;
  letter-spacing: -.14px;
  width: 540px;
  font-family: Neue Haas Grotesk Display Pro-65Medium, Helvetica;
  font-size: 64px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 413px;
  left: 1157px;
}

.work .text-wrapper-8 {
  color: #7d7d7d;
  letter-spacing: 0;
  width: 560px;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  position: absolute;
  top: 521px;
  left: 1157px;
}

.work .frame {
  background-image: url("mask-group.ded92d60.png");
  background-size: 100% 100%;
  width: 1051px;
  height: 1090px;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.82e8e93a.css.map */
